#layout {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 80px 1fr 50px;
  grid-template-columns: 350px 1fr;
  grid-template-areas:
    "header header"
    "sidebar main"
    "footer footer" ;
}

#layout > header {
  position: sticky;
  top: 0;
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  z-index: 50;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  @apply bg-white;

}

#layout > .sidebar {
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  @apply border-r bg-white;
}

#layout > footer {
  grid-area: footer;
  @apply bg-slate-300;
}

#layout > main {
  grid-area: main;
  background-color: #f5f5f5;
  width: calc(100vw - 355px);
}
