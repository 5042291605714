*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.power-input[disabled] {
  color: rgba(0, 0, 0, 0.4);
  background-color: #fff;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: default;
  opacity: 1;
}

.power-select-disabled.power-select:not(.power-select-customize-input) .power-select-selector {
  color: rgba(0, 0, 0, 0.4);
  background: #fff;
  cursor: default;
}

.badge {
  @apply py-1 px-4 text-white rounded-xl;
}

.icon {
  @apply rounded-full p-2;
}

.card {
  @apply shadow-xl rounded-3xl bg-white;
}

.menu-button {
  @apply bg-white cursor-pointer font-bold transition-all;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.infex-input-number-handler-wrap {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.leaflet-control {
  display: none;
}

.tox .tox-notification--in {
  display: none !important
}

.tox .tox-edit-area__iframe {
  background-color: transparent !important;
}