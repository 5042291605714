@import "power-styles";
@import "custom";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "layout";
@import 'leaflet/dist/leaflet.css';
/* @import 'tinymce/icons/default'; */
@import 'tinymce/skins/ui/oxide/skin.min.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@layer base {
  body {
    font-family: 'Arimo', system-ui, sans-serif;
    background-color: #fafafa;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[data-autocompleted] {
    background-color: transparent !important;
  }
}
